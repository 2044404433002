


const AllowOnly = ({ children, allowedRoles, userRole }) => {
    if (!allowedRoles.includes(userRole)) {
        return null;
    }
  return children;
};

export default AllowOnly;