import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatNumber, getViewedTransaction, groupTransactions, setViewedTransaction, sumArray } from '../../components/utils/functions';

import PageTitle from '../../components/PageTitle';
import { DatePicker } from 'react-responsive-calendar-picker';
import TransactionTicket from '../Finances/components/TransactionTicket';
import LoaderPage from '../../components/LoaderPage';

const TransactionsList = ({ type, sortie_caisse }) => {
  const [search, setSearch] = useState('');
  const [viewed, setViewed] = useState([]);
  const [page, setPage] = useState(1);
  const [pageOut, setPageOut] = useState(1);
  const [showAllAgencies, setShowAllAgencies] = useState(false);
  const [selectedtTransaction, setSelectedTransaction] = useState();
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });

  const { active_agency } = useSelector((state) => state.agencies);

  // entrees
  const { data: transactions, isLoading: loadingTransactions } = useSWR(
    showAllAgencies
      ? URLS.WALLET.allTrannsactions('', 1, ``)
      : URLS.SEARCH.all_transactions(
          active_agency?.id ?? "",
          '',
          page,
          `&type=${'DEPOSIT'}&limit=999999999999&ordering=-created_at&created_at__gte=${moment(dates?.checkin).format('YYYY-MM-DDTHH:MM')}&created_at__lte=${moment(dates?.checkout).format('YYYY-MM-DDTHH:MM')}`
        )
  );
  // Sorties
  const { data: expensesTransactions, isLoading: loadingExpensesTransactions } = useSWR(
    showAllAgencies
      ? URLS.WALLET.allTrannsactions('', 1, ``)
      : URLS.SEARCH.all_transactions(
          active_agency?.wallet?.id ?? "",
          '',
          pageOut,
          `&type=${'WITHDRAWAL'}&limit=999999999999&ordering=-created_at&created_at__gte=${moment(dates?.checkin).format('YYYY-MM-DDTHH:MM')}&created_at__lte=${moment(dates?.checkout).format('YYYY-MM-DDTHH:MM')}`
        )
  );

  const { t } = useTranslation();

  const handleChangePage = (event, pg) => {
    event.preventDefault();
    setPage(pg);
  };

  const handleChangePageOut = (event, pg) => {
    event.preventDefault();
    setPageOut(pg);
  };

  

  const handlePageChange = ({ page }) => {
    if (page) {
      console.log(page);
      setPage(page);
    }
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };


  const handleShow = (obj) => {
    setSelectedTransaction(obj);
    setViewedTransaction(obj.id)
    window.$('.details-trans-modal')?.modal('show');
  };

  useEffect(() => {
    window?.$('.details-trans-modal').on('hidden.bs.modal', function (e) {
      setViewed(getViewedTransaction());
    });
    setViewed(getViewedTransaction());
  }, [])

  return (
    <div className="row">
      <div className="col-md-6">
        <PageTitle
          title={'Opérations de Caisse'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {sortie_caisse ? " Historique des Opérations de Caisse" : "Suivi des Dépenses"}
              </li>
            </ol>
          }
        />
      </div>
      <div className="col-12">
        <div className="card">
          <div className="card-body">

            {/* <p className="card-title-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem aspernatur culpa animi. Veritatis doloremque earum, maiores, laboriosam deserunt voluptas magni provident ratione rerum a quasi quam sunt quisquam illo nam!
              </p> */}
              <div className="row">
              <div className="col-md-7">
                <div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="c-table-card-title">{sortie_caisse ? " Historique des Opérations de Caisse" : "Suivi des Dépenses"}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="">
                  <div className="">
                    <div className="row gy-2 gx-3 justify-content-end">
                      <div className="col-md-6">
                        <div>
                          {/* <label htmlFor="autoSizingInput">Rechercher</label> */}
                          {/* <input
                            onChange={(e) => setSearch(e.target.value)}
                            type="text"
                            className="form-control"
                            id="autoSizingInput"
                            placeholder="Bénéficiaire, Code du Bon"
                          /> */}
                          <div className="form search-form">
                            <i className="fa fa-search"></i>
                            <input
                              onChange={(e) => setSearch(e.target.value)}
                              type="text"
                              class="form-control form-input py-2"
                              placeholder="Bénéficiaire, Code du Bon"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-auto">
                        <div>
                          {/* <label htmlFor="transactionType">Choisir un Interval de Temps</label> */}
                          <div className='position-relative' style={{zIndex: 3}}>
                          <DatePicker dates={dates} setDates={setDates} open={open} setOpen={setOpen} normal={true}>
                            <button className="btn btn-primary " onClick={() => setOpen(!open)}>
                              Date du {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
                              {dates.checkout && 'au'} {dates.checkout && dates.checkout.toLocaleDateString()}
                            </button>
                          </DatePicker>
                            {/* <Select
                              className="basic-single-product"
                              classNamePrefix="select"
                              isLoading={isLoading}
                              value={selectedCustomer}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(newValue) => setSelectedCustomer(newValue)}
                              onInputChange={(value) => setSearchCustomers(value)}
                              name="customer"
                              options={customers?.results?.map((customer) => {
                                return {
                                  value: customer?.wallet?.id,
                                  label: `${customer?.first_name} ${customer?.last_name}`,
                                };
                              })}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-5'>
              <div className="card">
                <div className="card-body pt-2">
                  <div className="row">
                    <div className="col-sm-6 col-12">
                      <div className='mb-3'>
                        <h4 className="">Total des Encaissements: <span className="p-2 border border-1">{transactions?.results && formatNumber(sumArray(transactions?.results?.map(i => parseFloat(i.amount ?? 0))) ?? 0)} FCFA</span></h4>
                      </div>
                      <div className="">
                        {
                          loadingTransactions && <LoaderPage />
                        }
                        <div className="table-responsive" style={{maxHeight: '80vh'}}>
                          <table className="table align-middle table-nowrap mb-0">
                          <thead className='table-light' style={{position: "sticky", top: 0}}>
                            <tr>
                              <th className=''>Date</th>
                              <th className=''>Référence</th>
                              <th className=''>Montant</th>
                              {/* <th className='text-uppercase'>Bénéficiaire</th> */}
                              {/* <th className='text-uppercase'>Dernier Solde</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr>
                              <td ></td>
                              <td>
                                <strong>TOTAL: </strong>
                              </td>
                              <td></td>
                              <td>
                                {
                                  transactions?.results && formatNumber(sumArray(transactions?.results?.map(i => parseFloat(i?.amount ?? 0))) ?? 0)
                                } FCFA
                              </td>
                            </tr> */}
                            {groupTransactions(transactions?.results ?? [], transactions?.results ?? [])?.map((transfert) => (
                              <tr 
                              onClick={() => handleShow(transfert)} 
                              key={'trsc-' + transfert.id} 
                              style={{cursor: 'pointer'}}
                              className={sortie_caisse ? `${viewed?.includes(transfert?.id) ? 'badge-soft-primary' : 'badge-soft-primary'}` : `badge-soft-${transfert?.type === "DEPOSIT" ? 'success' : 'danger'}`}
                              >
                                
                                <td>
                                  {moment(transfert?.created_at).format('DD/MM/YYYY')} <span className='ms-3'>{moment(transfert?.created_at).format('HH:mm')}</span>
                                </td>
                                {
                                  transfert?.sortie_caisse ? 
                                  <td>
                                  { transfert?.reference} 
                                  </td>
                                  :
                                <td>
                                { transfert?.designation ? transfert?.designation : "#" + transfert?.reference} 
                                {/* {transfert?.beneficiary_name && ` (${(transfert?.beneficiary_name ?? "")})`} */}
                                </td>
                                }
                                <td>
                                  {
                                    transfert?.type === 'DEPOSIT' ? (formatNumber(transfert?.totalAmount) + " FCFA") : ""
                                  }
                                  {
                                    transfert?.reason === "APPROVISIONNEMENT_DE_COMPTE" && <span class="badge badge-soft-success ms-2 p-2">{transfert.payment_method}</span>
                                  }
                                </td>
                                {/* <td>
                                  {
                                    (formatNumber(transfert?.new_sold) + " FCFA")
                                  }
                                </td> */}
                                {/* <td>
                                  <p className="mb-0"> 
                                    {parseFloat(transfert.new_sold ?? '0')}{' '}
                                    FCFA
                                  </p>
                                </td> */}
                                
                                {/* <td>
                                <div className="d-flex gap-3">
                                  <a href="javascript:void(0);" className="btn btn-success btn-sm">
                                    <i className="mdi mdi-pencil"></i>
                                  </a>
                                  <a href="javascript:void(0);" className="btn btn-danger btn-sm">
                                    <i className="mdi mdi-delete"></i>
                                  </a>
                                </div>
                              </td> */}
                              </tr>
                            ))}
                          </tbody>
                          </table>
                        </div>
                        <div>
                        {/* {transactions?.count > 0 && (
                      <div className="paginations pt-3">
                        <ul className="pagination pagination-rounded">
                          <li>
                            <Link
                              tabIndex={-1}
                              onClick={(event) => handleChangePage(event, page - 1)}
                              className={`pager-prev ${page === 1 ? 'disabled' : ''}`}
                              to="#"
                            ></Link>
                          </li>
                          {new Array(Math.ceil(transactions.count / DEFAULT_PAGE_COUNT))
                            .fill(3)
                            .map((pag, index) => (
                              <li
                                key={'page' + index}
                                className={`page-item ${page === index + 1 ? 'active' : ''}`}
                                aria-current="page"
                              >
                                <Link
                                  className={`pager-number ${
                                    page * DEFAULT_PAGE_COUNT === Math.ceil(transactions.count)
                                      ? 'disabled'
                                      : ''
                                  } ${page === index + 1 ? 'active' : ''}`}
                                  to="#"
                                  tabIndex={-1}
                                  onClick={(event) => handleChangePage(event, index + 1)}
                                >
                                  <span className="page-link">{index + 1}</span>
                                </Link>
                              </li>
                            ))}
                          <li>
                            <Link
                              tabIndex={-1}
                              className={`pager-next ${page === 1 ? 'disabled' : ''}`}
                              to="#"
                            ></Link>
                          </li>
                        </ul>
                      </div>
                    )} */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                    <div className='mb-3'>
                        <h4 className="text-uppercas">Total des Décaissements: <span className="p-2 border border-1">{expensesTransactions?.results && formatNumber(sumArray(expensesTransactions?.results?.map(i => parseFloat(i.amount ?? 0))) ?? 0)} FCFA</span></h4>
                      </div>
                      <div className="">
                        {
                          loadingExpensesTransactions && <LoaderPage />
                        }
                        <div className="table-responsive" style={{maxHeight: '80vh'}}>
                          <table className="table align-middle table-nowrap mb-0">
                          <thead className='table-light' style={{position: "sticky", top: 0}}>
                            <tr>
                              <th className='text-uppercas'>Date</th>
                              <th className='text-uppercas'>Référence</th>
                              <th className='text-uppercas'>Montant</th>
                              {/* <th className='text-uppercase'>Bénéficiaire</th> */}
                              {/* <th className='text-uppercase'>Dernier Solde</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {/* <tr>
                              <td></td>
                              <td>
                                <strong>TOTAL: </strong>
                              </td>
                              <td></td>
                              <td></td>
                              <td>
                                {
                                 
                                } FCFA
                              </td>
                            </tr> */}
                            
                            {expensesTransactions?.results?.map((transfert) => (
                              <tr 
                              onClick={() => handleShow(transfert)} 
                              key={'trsc-' + transfert.id} 
                              style={{cursor: 'pointer'}}
                              className={`badge-soft-danger`}
                              >
                                
                                <td>
                                {moment(transfert?.created_at).format('DD/MM/YYYY')} <span className='ms-3'>{moment(transfert?.created_at).format('HH:MM')}</span>
                                </td>
                                {
                                  sortie_caisse ? 
                                  <td>
                                  { transfert?.reference} 
                                  </td>
                                  :
                                <td>
                                { transfert?.designation ? transfert?.designation : "#" + transfert?.reference} 
                                {transfert?.beneficiary_name && ` (${(transfert?.beneficiary_name ?? "")})`}
                                </td>
                                }
                                {/* <td>
                                  {
                                    (formatNumber(transfert?.amount) + " FCFA")
                                  }
                                </td> */}
                                {/* {
                                  sortie_caisse && 
                                  <td>
                                  {transfert.beneficiary_name}
                                </td>
                                } */}
                                {/* <td>
                                  {
                                    transfert?.type === 'DEPOSIT' ? (transfert?.amount + " FCFA") : ""
                                  }
                                  {
                                    transfert?.reason === "APPROVISIONNEMENT_DE_COMPTE" && <span class="badge badge-soft-success ms-2 p-2">{transfert.payment_method}</span>
                                  }
                                </td> */}
                                <td>
                                  {
                                    transfert?.type === 'DEPOSIT' ? "" : (formatNumber(transfert?.amount) + " FCFA")
                                  }
                                </td>
                                {/* <td>
                                  <p className="mb-0"> 
                                    {parseFloat(transfert.new_sold ?? '0')}{' '}
                                    FCFA
                                  </p>
                                </td> */}
                                
                                {/* <td>
                                <div className="d-flex gap-3">
                                  <a href="javascript:void(0);" className="btn btn-success btn-sm">
                                    <i className="mdi mdi-pencil"></i>
                                  </a>
                                  <a href="javascript:void(0);" className="btn btn-danger btn-sm">
                                    <i className="mdi mdi-delete"></i>
                                  </a>
                                </div>
                              </td> */}
                              </tr>
                            ))}
                          </tbody>
                          </table>
                        </div>
                        <div>
                        {/* {expensesTransactions?.count > 0 && (
                      <div className="paginations pt-3">
                        <ul className="pagination pagination-rounded">
                          <li>
                            <Link
                              tabIndex={-1}
                              onClick={(event) => handleChangePageOut(event, page - 1)}
                              className={`pager-prev ${page === 1 ? 'disabled' : ''}`}
                              to="#"
                            ></Link>
                          </li>
                          {new Array(Math.ceil(expensesTransactions.count / DEFAULT_PAGE_COUNT))
                            .fill(3)
                            .map((pag, index) => (
                              <li
                                key={'page' + index}
                                className={`page-item ${page === index + 1 ? 'active' : ''}`}
                                aria-current="page"
                              >
                                <Link
                                  className={`pager-number ${
                                    page * DEFAULT_PAGE_COUNT === Math.ceil(expensesTransactions.count)
                                      ? 'disabled'
                                      : ''
                                  } ${page === index + 1 ? 'active' : ''}`}
                                  to="#"
                                  tabIndex={-1}
                                  onClick={(event) => handleChangePage(event, index + 1)}
                                >
                                  <span className="page-link">{index + 1}</span>
                                </Link>
                              </li>
                            ))}
                          <li>
                            <Link
                              tabIndex={-1}
                              className={`pager-next ${page === 1 ? 'disabled' : ''}`}
                              to="#"
                            ></Link>
                          </li>
                        </ul>
                      </div>
                    )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative">

      <div
                  className="modal fade details-trans-modal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeTransModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeTransModalLabel">
                          {'Bon de caisse N° ' + selectedtTransaction?.reference}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <TransactionTicket
                          invoice={selectedtTransaction}
                          handleClose={handleClose}
                        />
                      </div>
                    </div>
                  </div>
                </div>
      </div>
    </div>
  );
};

export default TransactionsList;
