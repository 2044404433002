import { ROLE_ADMIN, ROLE_EMPLOYEE, ROLE_OP_CAISSE, ROLE_CHEF_SALLE, ROLE_CHEF_AGENCE, ROLE_RESPONSABLE_EXPLOITATION } from "../utils/consts";



export const navItems = [

    {
        id: "2",
        title: "Tableau de bord",
        children: [],
        icon: "dashboard",
        link: "",
        group: [...ROLE_ADMIN, ...ROLE_CHEF_AGENCE, ...ROLE_OP_CAISSE],
        all_agencies: "also_simple_agency",
    },
    {
        id: "1",
        title: "Menu",
        simple_text:true,
        group: ROLE_EMPLOYEE,
        all_agencies: false,
    },
    {
        id: "2-1",
        title: "Facturations",
        children: [],
        icon: "receipt_long",
        link: "/customers/list",
        group: ROLE_OP_CAISSE,
        all_agencies: false,
    },
    {
        id: "2-5",
        title: "Planning RDV",
        children: [],
        icon: "event",
        link: "/appointments/list",
        group: [...ROLE_CHEF_SALLE, ...ROLE_ADMIN],
        all_agencies: false,
    },
    {
        id: "2-6",
        title: "Factures en Attente",
        children: [],
        icon: "receipt_long",
        link: "/invoices/pending",
        group: [...ROLE_OP_CAISSE, ...ROLE_CHEF_AGENCE],
        all_agencies: false,
    },
    {
        id: "23-3",
        title: "Factures",
        icon: "receipt_long",
        link: "/",
        group: [...ROLE_OP_CAISSE, ...ROLE_ADMIN, ...ROLE_CHEF_AGENCE],
        all_agencies: false,
        children: [
            {
                id: "24-3",
                title: "Toutes les Factures",
                icon: "receipt_long",
                link: "/invoices/list",
                group: [...ROLE_OP_CAISSE, ...ROLE_ADMIN, ...ROLE_CHEF_AGENCE],
                all_agencies: false,
            },
            {
                id: "28-3",
                title: "Factures Offertes",
                icon: "receipt_long",
                link: "/invoices/gifts",
                group: [...ROLE_OP_CAISSE, ...ROLE_ADMIN, ...ROLE_CHEF_AGENCE],
                all_agencies: false,
            },
        ]
    },
    {
        id: "2-2",
        title: "Caisse",
        group: [...ROLE_OP_CAISSE, ...ROLE_ADMIN],
        all_agencies: true,
        children: [
            {
                id: "2-2-1",
                title: "Caisse Principale",
                children: [],
                icon: "point_of_sale",
                link: "/finances/checkout",
                group: [...ROLE_OP_CAISSE, ...ROLE_ADMIN],
                all_agencies: true,
            },
            {
                id: "2-2-2",
                title: "Opérations de caisse",
                children: [],
                icon: "universal_currency_alt",
                link: "/finances/expenses",
                group: [...ROLE_OP_CAISSE, ...ROLE_ADMIN],
                all_agencies: true,
            },
            {
                id: "2-5",
                title: "Autres caisses",
                children: [],
                icon: "receipt_long",
                link: "/finances/payments",
                group: [...ROLE_OP_CAISSE, ...ROLE_ADMIN],
                all_agencies: true,
            },
            {
                id: "21",
                title: "Coffre",
                icon: "payments",
                link: "/finances/checkouts",
                group: ROLE_ADMIN,
                all_agencies: true,
            },
        ],
        icon: "point_of_sale",
        link: "/finances/checkout",
    },
    {
        id: "2-2-bis",
        title: "Caisse",
        group: ROLE_ADMIN,
        all_agencies: false,
        children: [
            {
                id: "2-2-1-bis",
                title: "Caisse Principale",
                children: [],
                icon: "point_of_sale",
                link: "/finances/checkout",
                group: [...ROLE_ADMIN],
                all_agencies: false,
            },
            {
                id: "2-2-2-bis",
                title: "Opérations de caisse",
                children: [],
                icon: "universal_currency_alt",
                link: "/finances/expenses",
                group: ROLE_ADMIN,
                all_agencies: false,
            },
            {
                id: "2-5-bis",
                title: "Autres caisses",
                children: [],
                icon: "receipt_long",
                link: "/finances/payments",
                group: ROLE_ADMIN,
                all_agencies: false,
            },
            {
                id: "21-bis",
                title: "Coffre",
                icon: "payments",
                link: "/finances/checkouts",
                group: ROLE_ADMIN,
                all_agencies: false,
            },
        ],
        icon: "point_of_sale",
        link: "/finances/checkout",
    },
    {
        id: "3",
        title: "Employés",
        icon: "group",
        link: "/employees/list",
        group: ROLE_ADMIN,
        all_agencies: false,
    },
    {
        id: "3-bis",
        title: "Employés",
        icon: "group",
        link: "/employees/list",
        group: [...ROLE_CHEF_AGENCE, ...ROLE_ADMIN],
        all_agencies: true,
    },

    {
        id: "12-1s",
        title: "Services",
        icon: "partner_exchange",
        link: "/services/list",
        group: ROLE_CHEF_AGENCE,
        all_agencies: false,
    },
    {
        id: "12-2",
        title: "Stocks",
        icon: "store",
        link: "/stocks",
        group: [...ROLE_OP_CAISSE, ...ROLE_ADMIN, ...ROLE_CHEF_AGENCE],
        all_agencies: false,
    },
    {
        id: "19",
        title: "Rapports d'activité",
        group: ROLE_RESPONSABLE_EXPLOITATION,
        icon: "analytics",
        all_agencies: false,
        link: "/",
        children: [
            {
                id: "20",
                title: "Rapport d'activité",
                icon: "monitoring",
                link: "/repports/daily",
                group: ROLE_RESPONSABLE_EXPLOITATION,
                all_agencies: false,
            },
            {
                id: "22",
                title: "Rapport de performance",
                icon: "ssid_chart",
                link: "/repports/perf",
                group: ROLE_RESPONSABLE_EXPLOITATION,
                all_agencies: false,
            },
        ]
    },

    {
        id: "19-bis",
        title: "Rapports d'activité",
        group: ROLE_RESPONSABLE_EXPLOITATION,
        icon: "analytics",
        all_agencies: true,
        link: "/",
        children: [
            {
                id: "21",
                title: "Rapport global",
                icon: "monitoring",
                link: "/repports/global",
                group: ROLE_RESPONSABLE_EXPLOITATION,
                all_agencies: true,
            },
            {
                id: "23",
                title: "Rapport de performance Global",
                icon: "ssid_chart",
                link: "/repports/perf-global",
                group: ROLE_RESPONSABLE_EXPLOITATION,
                all_agencies: true,
            }
        ]
    },
    {
        id: "23-2",
        title: "Gestion des clients",
        icon: "settings",
        link: "/",
        group: [...ROLE_CHEF_AGENCE, ...ROLE_ADMIN, ...ROLE_RESPONSABLE_EXPLOITATION],
        all_agencies: true,
        children: [
            {
                id: "24-2",
                title: "Clients",
                icon: "storefront",
                link: "/customers-management/list",
                group: [...ROLE_CHEF_AGENCE, ...ROLE_ADMIN, ...ROLE_RESPONSABLE_EXPLOITATION],
                all_agencies: true,
            },
            {
                id: "28-2",
                title: "Suivi des Gratuités",
                icon: "receipt_long",
                link: "/customers-management/gifts",
                group: [...ROLE_CHEF_AGENCE, ...ROLE_ADMIN, ...ROLE_RESPONSABLE_EXPLOITATION],
                all_agencies: true,
            },
            {
                id: "25-2",
                title: "Clients Endettés",
                icon: "storefront",
                link: "/customers-management/indebted-customers",
                group: [...ROLE_CHEF_AGENCE, ...ROLE_ADMIN, ...ROLE_RESPONSABLE_EXPLOITATION],
                all_agencies: true,
            },
        ]
    },
    {
        id: "23",
        title: "Configurations",
        icon: "settings",
        group: [...ROLE_ADMIN, ...ROLE_RESPONSABLE_EXPLOITATION],
        link: "/",
        children: [
            {
                id: "24",
                title: "Agences",
                icon: "storefront",
                link: "/configurations/agencies",
                group: ROLE_RESPONSABLE_EXPLOITATION,
                all_agencies: true,
            },
            {
                id: "28",
                title: "Fournisseurs",
                icon: "local_shipping",
                link: "/configurations/suppliers",
                group: ROLE_RESPONSABLE_EXPLOITATION,
                all_agencies: true,
            },
            {
                id: "31",
                title: "Coupons",
                icon: "confirmation_number",
                link: "/configurations/coupons",
                group: ROLE_RESPONSABLE_EXPLOITATION,
                all_agencies: true,
            }
        ]
    }
]
