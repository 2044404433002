import React, { useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import ExportExcel from '../../components/download/DownloadExcel';
import NewCustomerForm from './components/NewCustomerForm';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmDeletion } from '../../components/dletion';
import { useTranslation } from 'react-i18next';
import CustomerDetails from './details';
import NewInvoiceForm from '../Invoices/components/NewInvoiceForm';
import NewAppointmentForm from '../Appointment/components/NewAppointmentForm';
import DepositForm from '../Finances/Checkouts/DepositForm';
import InvoiceTicket from '../Invoices/components/InnvoiceTicket';
import PageTitle from '../../components/PageTitle';
import { formatNumber, formateDate } from '../../components/utils/functions';
import { DEFAULT_PAGE_COUNT } from '../../components/utils/consts';
import LoaderPage from '../../components/LoaderPage';
import Pagination from "react-bootstrap/Pagination";
import PaginationComponent from '../../components/Pagination';
import EmptyDataToDisplay from '../../components/EmptyData';

const exportColumns = [
  {
    label: 'Code client',
    value: 'reference',
  },
  {
    label: 'Nom complet',
    value: 'full_name',
  },
  {
    label: 'Telephone',
    value: 'phone_number',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Date de naissance',
    value: 'birth_date',
  },
  {
    label: "Date d'ajout",
    value: 'created_at',
  },
  {
    label: 'Solde en compte',
    value: 'sold',
  },
  {
    label: 'Cree par',
    value: 'created_by',
  },
];

const paginationComponentOptions = {
  // noRowsPerPage: true,
};

const CustomersList = () => {
  const [selected, setSelected] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [search, setSearch] = useState('');
  const [toEdit, setToEdit] = useState();
  const [page, setPage] = useState(1);
  const [selectedInvoice, setSelectedInvoice] = useState();

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: customers,
    isLoading,
    mutate,
  } = useSWR(URLS.SEARCH.customers(active_agency?.id, search, page, ''));

  const { t } = useTranslation();

  const handleDisplayInvoice = (invoice) => {
    // console.log('invoice ====================================', invoice);

    setSelectedInvoice(invoice);
  };

  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', selectedRows);
    setSelected(selectedRows);
  };

  const handleChangePage = (page) => {
    // event?.preventDefault();
    if (page) {
      setPage(page);
    }
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleSuccessAppointment = () => {
    window.$('.new-appointment-modal')?.modal('hide');
  };

  const handleCloseAppointment = () => {
    // window.$(".new-invoice-modal .btn-close")?.click();
    // var detailsModal = new window.bootstrap.Modal(document.getElementById('customerDetailsModal'), {
    // })
    // detailsModal?.show();
  };

  const handleCloseDetails = () => {
    window?.$(".modal-backdrop.fade").hide()
  }

  const handleSuccess = (customer) => {
    setToEdit();
    mutate();
    setSelectedCustomer(customer);
  };

  const handleEdit = (obj) => {
    setToEdit(obj);
  };

  const handleDelete = (obj) => {
    confirmDeletion(URLS.CUSTOMERS.delete(obj.id), t, mutate);
  };

  const hadleSetSelectedCustomer = (customer) => {
    if (customer) {
      setSelectedCustomer(customer);
    } 
  }

  const handleShowCustomerDetails = (customer) => {
    if (customer) {
      setSelectedCustomer(customer);
      // console.log("customer -------------> ", customer);
    }
    var detailsModal = new window.bootstrap.Modal(
      document.getElementById('customerDetailsModal'),
      {}
    );
    detailsModal?.show();
  };

  const columns = useMemo(() => [
    {
      name: 'Code client',
      selector: (row) => `${row.reference}`,
      sortable: true,
      val: 'reference',
    },
    {
      name: 'Nom complet',
      selector: (row) => `${row.full_name ?? ''}`,
      sortable: true,
      val: 'full_name',
    },
    {
      name: 'Contact',
      selector: (row) => `${row.phone_number}`,
      sortable: true,
      val: 'phone_number',
    },
    {
      name: 'Adresse Mail',
      selector: (row) => `${row.email}`,
      sortable: true,
      val: 'email',
    },
    {
      name: 'Solde en Compte',
      selector: (row) => (
        <span className={`${row?.wallet > 0 ? 'text-success' : 'text-danger'}`}>
          {row?.wallet ?? 0}
        </span>
      ),
      sortable: true,
      val: 'email',
    },
    {
      name: 'Date de Naissance',
      selector: (row) => row.birth_date,
      sortable: true,
      val: 'birth_date',
    },
    {
      name: "Date d'Ajout",
      selector: (row) => moment(row.created_at).format('lll'),
      sortable: true,
      val: 'created_at',
    },
    // {
    //   name: 'Actions',
    //   selector: (row) => (
    //     <div className="d-flex gap-3">
    //       <Link to="#" onClick={() => handleEdit(row)} data-bs-toggle="modal" data-bs-target=".new-client-modal" className="btn btn-success btn-sm">
    //         <i className="mdi mdi-pencil"></i>
    //       </Link>
    //       <Link to={`/customers/${row.id}`} className="btn btn-warning btn-sm">
    //         <i className="mdi mdi-eye"></i>
    //       </Link>
    //       <Link to="#" onClick={() => handleDelete(row)} className="btn btn-danger btn-sm">
    //         <i className="mdi mdi-delete"></i>
    //       </Link>
    //     </div>
    //   ),
    // },
  ]);

  useEffect(() => {
    // window?.$('.new-appointment-modal').on('hidden.bs.modal', function (e) {

    //   handleShowCustomerDetails()
    // })
    window?.$('.new-invoice-modal').on('hidden.bs.modal', function (e) {
      handleShowCustomerDetails();
    });
    window?.$('.new-client-modal').on('hidden.bs.modal', function (e) {
      handleShowCustomerDetails();
    });
    window?.$('.customer-details-modal').on('hidden.bs.modal', function (e) {
      // window?.$(".modal-backdrop.fade").hide()
    });
    window?.$('.customer-details-modal').on('hidden.bs.modal', function (e) {
      // window?.$(".modal-backdrop.fade").hide()
    });

    window?.$('.details-invoice-modal').on('hidden.bs.modal', function () {
      window?.$('.modal-backdrop.fade').hide();
    });
    // new-client-modal
  }, []);

  return (
    <div className="row">
      <div className="col-md-6">
        <PageTitle
          title={'Facturations'}
          breadcrumbs={
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Facturations
              </li>
            </ol>
          }
        />
      </div>
      <div className="col-md-6">
        <div className="d-flex h-100">
          <div className="ms-auto my-auto">
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              data-bs-toggle="modal"
              data-bs-target=".new-client-modal"
            >
              {' '}
              + Ajouter un client
            </button>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="card mb-0">
          <div className="card-header">
            <div className="row">
              <div className="col-md-7">
                <div>
                  <div className="c-table-card-title">Facturations</div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row">
                  <div className="col">
                    <div>
                      <div className="form search-form">
                        <i className="fa fa-search"></i>
                        <input
                          onChange={(e) => setSearch(e.target.value)}
                          type="text"
                          class="form-control form-input py-2"
                          placeholder="Rechercher"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div>
                      {!isLoading && <ExportExcel
                        label={'Exporter'}
                        link={URLS.AGENCIES.exportCustomers(active_agency?.id)}
                      />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            <div className="d-flex justify-content-between">
              <div>
                <h3 className="text-uppercase"> </h3>
              </div>
              <div>
                {!isLoading && <div
                  className="modal fade new-client-modal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="myLargeModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="myLargeModalLabel">
                          {toEdit
                            ? 'Modifitation du client <<' +
                              (toEdit.first_name ?? '') +
                              ' ' +
                              (toEdit.last_name ?? '') +
                              '>>'
                            : 'Nouveau client'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <NewCustomerForm
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          toEdit={toEdit}
                        />
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
            {!isLoading && <div
              className="modal fade customer-details-modal"
              tabIndex={-1}
              // role="dialog"
              aria-labelledby="myLargeModalLabel"
              aria-hidden="true"
              id="customerDetailsModal"
            >
              <div className="modal-dialog  modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="myLargeModalLabel">
                      Details du client
                      {/* "{selectedCustomer?.first_name} {selectedCustomer?.last_name}" */}
                    </h5>
                    <span
                      className={`fs-4 ms-2 rating ${selectedCustomer?.regular ? 'active' : ''}`}
                    >
                      <i className={`mdi mdi-star`}></i>
                    </span>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={handleCloseDetails}
                    ></button>
                  </div>
                  <div className="modal-body modal-bg">
                    <CustomerDetails
                      handleDeleteCustomer={handleDelete}
                      customerId2={selectedCustomer?.id}
                      hideCreationForms={true}
                      handleEdit={handleEdit}
                      hadleSetSelectedCustomer={hadleSetSelectedCustomer}
                    />
                  </div>
                </div>
              </div>
            </div>}

            {/* <p className="card-title-desc">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem aspernatur culpa animi. Veritatis doloremque earum, maiores, laboriosam deserunt voluptas magni provident ratione rerum a quasi quam sunt quisquam illo nam!
            </p> */}

            <div>
              {/* <div className="row py-5">
                <div className="col-md-6 col-12">
                  <div>
                    <input
                      
                      type="text"
                      className="form-control"
                      id="autoSizingInput"
                      placeholder="Rechercher ..."
                    />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="d-flex justify-content-end">
                    
                  </div>
                </div>
              </div> */}
              {/* <div>{page}</div> */}
              {
              isLoading ? <LoaderPage />
              : <div class="table-responsive">
                <table class="table table-hover c-table-hover">
                  <thead>
                    <tr>
                      {columns?.map((item, i) => (
                        <th scope="col" key={'itm_' + i}>
                          {item?.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {(customers?.results ?? [])?.map((row) => (
                      <tr class="" key={'ki_' + row?.id} onClick={() => handleShowCustomerDetails(row)}>
                        <td>{row.reference}</td>
                        <td>{`${row.full_name ?? ''}`}</td>
                        <td>{row.phone_number}</td>
                        <td>{row.email !== 'null' ? row.email : '-'}</td>
                        <td>
                          <span
                            className={`${
                              row?.wallet > 0 ? 'text-success' : 'text-danger'
                            }`}
                          >
                            {formatNumber(row?.wallet ?? 0)}
                          </span>
                        </td>
                        <td>{row?.birth_date ? moment(row.birth_date).format('DD/MM/YYYY') : '-'}</td>
                        <td>{formateDate(row?.created_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              }

              <div>
                {
                  customers?.results?.length === 0 && <EmptyDataToDisplay />
              }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Table pagination */}
      <div className="d-flex">
        <div className="ms-auto">

          <div className="pt-3">
          {
            customers?.count > 0 && (
              <PaginationComponent currentPage={page} handlePageChange={handleChangePage} totalPages={Math.ceil(customers.count / DEFAULT_PAGE_COUNT) ?? 0} />
            )
          }
          </div>

        </div>
      </div>
      {/* Creations form */}

      <div>
        {!isLoading && <div
          className="modal fade new-invoice-modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeModalLabel2"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeModalLabel2">
                  {`Nouvelle Facture Pour le client: ${selectedCustomer?.first_name} ${selectedCustomer?.last_name}`}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <NewInvoiceForm
                  handleClose={handleClose}
                  onSuccess={handleSuccess}
                  toEdit={toEdit}
                  customerId2={selectedCustomer?.id}
                  handleDisplayInvoice={handleDisplayInvoice}
                />
              </div>
            </div>
          </div>
        </div>}
        {!isLoading && <div
          className="modal fade new-appointment-modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeModalLabel">
                  {'Nouvelle Prise de RDV Pour le Client: ' +
                    selectedCustomer?.first_name +
                    ' ' +
                    selectedCustomer?.last_name}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  data-bs-toggle="modal"
                  data-bs-target=".customer-details-modal"
                ></button>
              </div>
              <div className="modal-body">
                <NewAppointmentForm
                  handleClose={handleCloseAppointment}
                  onSuccess={handleSuccessAppointment}
                  toEdit={toEdit}
                  customerId2={selectedCustomer?.id}
                />
              </div>
            </div>
          </div>
        </div>}
      </div>
      {/* Appro customer account */}
      {!isLoading && <div
        // {!hideCreationForms && <div
        className="modal fade new-deposit-modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="myLargeModalLabel2">
                {'Approvisionnement du compte client'}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <DepositForm
                handleClose={handleClose}
                onSuccess={handleSuccess}
                wallet={selectedCustomer?.wallet}
              />
            </div>
          </div>
        </div>
      </div>}
      {/* Invoice modal */}
      {!isLoading && <div>
        <div
          className="modal fade details-invoice-modal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myLargeInvoiceModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myLargeInvoiceModalLabel">
                  {'Facture ' + selectedInvoice?.reference}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <InvoiceTicket invoice={selectedInvoice} />
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default CustomersList;
